import loginRequest from '@/utils/loginRequest'
import Qs from 'qs'




//土地展首页数据
export function tudizhanHomeList(params) {
	return loginRequest({
    url: '/frontend/website/land-show/homepage',
		method: 'post',
		data:Qs.stringify(params)
	})
}



//土地详情数据
export function tudizhanDetail(params) {
	return loginRequest({
    url: '/frontend/website/land-show/detail',
		method: 'post',
		data:Qs.stringify(params)
	})
}


//土地展新闻详情
export function tudizhanNewsDetail(params) {
	return loginRequest({
    url: '/frontend/website/land-show/news-detail',
		method: 'post',
		data:Qs.stringify(params)
	})
}



//土地展历程详情
export function tudizhanLichengDetail(params) {
	return loginRequest({
    url: '/frontend/website/land-show/course-detail',
		method: 'post',
		data:Qs.stringify(params)
	})
}





//峰会展首页数据
export function fenghuiHomeList(params) {
	return loginRequest({
    url: '/frontend/website/summit/homepage',
		method: 'post',
		data:Qs.stringify(params)
	})
}



//峰会详情数据
export function fenghuiDetail(params) {
	return loginRequest({
    url: '/frontend/website/summit/detail',
		method: 'post',
		data:Qs.stringify(params)
	})
}




//峰会展新闻详情
export function fenghuiNewsDetail(params) {
	return loginRequest({
    url: '/frontend/website/summit/news-detail',
		method: 'post',
		data:Qs.stringify(params)
	})
}



//峰会展历程详情
export function fenghuiLichengDetail(params) {
	return loginRequest({
    url: '/frontend/website/summit/course-detail',
		method: 'post',
		data:Qs.stringify(params)
	})
}




