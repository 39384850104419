<template>
	<div class="con">

		<div class="header">
			<div class="bluetitle bluetitle1">
				<div class="blue"></div>
				<div class="title">{{ name }}</div>
			</div>
			<div class="citylist">
				<div class="innerbox">
					<div @click="changeYear(item.year)" :class="{ 'yearbtn': true, 'currentbtn': item.checked }" :key="item.id"
						v-for="item in year">{{
							item.year
						}}年
					</div>
				</div>
			</div>
		</div>
		<viewer :images="pics">
			<div class="pics">
				<div class="pic" :key="item.id" v-for="item in pics">
					<img :src="item.pic_url" alt="">
				</div>
			</div>
		</viewer>
		<div class="info">
			<div class="year">{{ course_year }}</div>
			<div class="title">{{ title }}</div>
		</div>

	</div>
</template>

<script>
import BlueTitle from './BlueTitle.vue';
export default {
	components: { BlueTitle },
	name: 'PcWebsiteLicheng',
	props: {
		licheng: {
			type: Object,
			default: {}
		},
		name: ''
	},

	watch: {
		licheng: {
			handler(nv) {

				this.pics = nv.pics
				this.year = nv.year
				this.course_year = nv.course_year
				this.title = nv.title
			},
			deep: true,
			immediate: true
		},
		name: {
			handler(nv) {
				this.title = nv
			}
		}
	},

	data() {
		return {
			title: '展会历程',
			pics: [],
			year: [],
			course_year: '',
			title: ''
		};
	},

	mounted() {

		// var container = document.querySelector('.container');
		// var items = document.querySelectorAll('.item');
		// var itemWidth = items[0].offsetWidth;
		// var itemCount = items.length;
		// container.style.width = itemWidth * itemCount + 'px';
	},

	methods: {
		//获取year
		changeYear(year) {
			this.$emit('changeYear', year)
		}

	},
};
</script>

<style lang="scss" scoped>
.year {
	margin-top: 5px;
	height: 25px;
	font-size: 18px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #000000;
	line-height: 25px;
}

.title {
	height: 22px;
	font-size: 16px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #000000;
	line-height: 22px;
}

.pics {
	height: 370px;
	margin-left: 20px;
	overflow: hidden;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;

	.pic {
		width: 280px;
		height: 168px;
		margin-top: 16px;
		margin-right: 14px;

		img {
			width: 280px;
			height: 168px;
		}
	}
}

.innerbox {

	height: 100%;

	display: flex;
	white-space: nowrap;
	overflow-x: auto;
	justify-content: flex-start;
	align-items: center;
	width: auto;
	// flex-wrap: nowrap;
	width: 100%;
}

.yearbtn {
	flex-shrink: 0;
	float: left;
	cursor: pointer;
	margin-left: 15px;
	width: 55px;
	height: 28px;
	background: #F2F2F2;
	border-radius: 2px;
	color: #000;
	line-height: 28px;
}

.currentbtn {
	background: #00A3E4;
	border-radius: 2px;
	color: #fff;
}

.con {
	width: 1200px;
	height: 497px;
	background: #FFFFFF;
	overflow: hidden;
}

.header {
	display: flex;

	.citylist {
		flex: 1;
		margin-right:10px;
		// overflow: scroll;
		// background: red;
	}


}


.bluetitle {
	display: flex;
	height: 68px;
	min-width: 120px;

	justify-content: flex-start;
	align-items: center;

	.blue {
		margin-left: 20px;
		width: 4px;
		height: 26px;
		background: #00A3E4;
	}

	.title {
		margin-left: 10px;
		height: 28px;
		font-size: 20px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #000000;
		line-height: 28px;
	}

}
</style>